
import { defineComponent } from 'vue';
import SimulationDataHelper from '@/helpers/SimulationDataHelper';
import globals from '../../../helpers/globals';
import FormButton from '../../../components/forms/Button.vue';
import FormInput from '../../../components/forms/Input.vue';
import AccountDataService from '../../../services/AccountDataService';
import FileDataService from '../../../services/FileDataService';

export default defineComponent({
  name: 'Login',
  mixins: [
    globals,
  ],
  components: {
    FormButton,
    FormInput,
  },
  data() {
    return {
      parameters: {},
      form: {
        serverError: null,
        inputs: {
          email: {
            value: null,
            rule: 'required|email',
            error: null,
          } as {[key: string]: any},
          password: {
            value: null,
            rule: 'required',
            error: null,
          } as {[key: string]: any},
        } as {[key: string]: any},
      },
    };
  },
  created() {
    const vm = this;

    /* LOAD DEPENDENCIES IF DONT ALREADY EXIST */
    this.$store.dispatch('isLoading', true);

    if (vm.$route.name === 'Login') {
      this.load((data: any) => {
        vm.$store.dispatch('setSelectedSimulation', data);

        vm.init();
      });
    } else {
      this.$store.dispatch('isLoading', false);
      this.$store.dispatch('toggleProgressBar', false);
      this.$store.dispatch('toggleSideBar', false);
      this.$store.dispatch('toggleTopBar', false);
    }
  },
  mounted() {
    /* Scroll page to top (case of mobile phones) */
    this.scrollToTop();

    const tagArr = document.getElementsByTagName('input');

    for (let i = 0; i < tagArr.length; i += 1) {
      tagArr[i].click();
    }

    document.getElementById('email')!.click();
  },
  methods: {
    /* COMPONENT INITIALIZER */
    init() {
      /* FLAG STEP AS UNCOMPLETED */
      this.setStepAsCompleted(this, false);

      /* SHOW SIDE AND NAV BAR IF USER FALLS DIRECTLY INTO CURRENT COMPONENT */
      this.$store.dispatch('toggleProgressBar', false);
      this.$store.dispatch('toggleSideBar', false);
      this.$store.dispatch('toggleTopBar', false);

      /* STOP LOADING */
      this.$store.dispatch('isLoading', false);
    },
    /* LOAD DATA THROUGH APIS */
    load(callback: any) {
      if (this.$route.name === 'Login') {
        SimulationDataHelper.prepareComponentResult(
          this.$store,
          this.$route.params.simulation as string,
          callback,
        );
      }
    },
    goToForgotPassword(event: any) {
      event.preventDefault();

      this.$router.push({
        name: 'ForgotPassword',
        params: {},
      });
    },
    goToNextStep(event: any) {
      const vm = this;

      event.preventDefault();

      vm.$store.dispatch('isLoading', true);

      // Load recaptcha token
      this.recaptcha(this)
        .then((resp: any) => {
          AccountDataService.login(this.form.inputs.email.value, this.form.inputs.password.value, this.$store.state.recaptcha)
            .then((response: any) => {
              vm.form.serverError = null;
              const { data } = response.data;

              vm.setAccessToken(data.access_token);
              vm.setRefreshToken(data.refresh_token);

              AccountDataService.getProfile(localStorage.getItem('access_token'))
                .then((responses: any) => {
                  vm.$store.dispatch('setProfile', responses.data.data);
                });

              if (vm.$route.name === 'Login') {
                FileDataService.create(data.access_token, vm.$route.params.simulation as string)
                  .then((responses: any) => {
                    vm.$store.dispatch('setSelectedFile', data);
                  })
                  .catch((e: Error) => { /* */ });
              } else if (vm.$store.state.afterLogin !== null) {
                const params = vm.$store.state.afterLogin;

                vm.$store.dispatch('setAfterLogin', null);

                vm.$router.push(params);
              } else {
                vm.$router.push({
                  name: 'SegmentSelection',
                });
              }

              vm.$store.dispatch('isLoading', false);
            }).catch((e: any) => {
              vm.$store.dispatch('isLoading', false);
            });
        });
    },
    goToRegister(event: any) {
      event.preventDefault();

      this.$store.dispatch('setAfterLogin', {});

      this.$router.push({
        name: 'Register',
        params: {
          simulation: this.$route.params.simulation as string,
        },
      });
    },
    goToSegmentSelection() {
      if (this.$route.name === 'LoginBase') {
        this.$router.push({
          name: 'SegmentSelection',
        });
      } else {
        this.$router.go(-1);
      }
    },
  },
});
